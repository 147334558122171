
.subpage-container {
    padding: 24px 0;
}

.max-container {
    padding: 20px;
    @include desktop {
        max-width: 1116px;
        margin: auto;
        padding: 0;
    }
}

/** overwrite antd **/
.ant-divider {
 span {
     font-weight: bold;
     font-size: 22px;
     @include desktop {
         font-size: 26px;
     }
 }   
}

.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
    border-top-color: $color-primary;
}