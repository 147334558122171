@import "styles/styles.scss";

.productBanner {
  @include displayFlex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow: auto;

  @media only screen and (max-width: 800px) {
    .productBanner__content {
      padding: 40px 20px 40px 20px;
      @include displayFlex;
      flex-direction: column-reverse;
      div {
        width: 100%;
      }
    }
  }

  @media print {
    margin: 0px;
    padding: 0px 20px;
    width: 100%;
  }

  &__content {
    @include displayFlex;
    padding: 40px 64px 40px 64px;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    gap: 2%;

    &__text {
      line-height: 1.5;
      width: 35%;
      @include displayFlex;
      flex-direction: column;
      gap: 20px;

      h1 {
        margin-bottom: 0px;
      }
      button {
        width: fit-content;
      }
      li {
        list-style-type: none;
        margin: 0px !important;
        line-height: 2;
      }
      .anticon {
        padding-right: 10px;
      }
      svg {
        fill: #2f73f6;
      }
    }
    &__button {
      @include displayFlex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__img {
      width: 65%;
      overflow: hidden;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &--small {
        width: 55%;
      }
    }
  }

  .ant-divider-horizontal {
    margin: 0px;
  }
}

.rapidSearchContainer {
  padding: 26.8px 64px 60px 64px;
  height: 450px;

  h4 {
    color: $nw-red !important;
  }

  .nwSearch__content {
    max-height: 300px;
  }

  @media only screen and (max-width: 800px) {
    padding: 26.8px 8px 60px 8px;
    .nwSearch__content {
      max-height: unset;
    }
    .nwSearch {
      z-index: 99;
    }
  }
}

.product-detail__breadcrumb {
  padding-left: 20px !important;
}
