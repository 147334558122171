@import "styles/shared";

.modal {
  width: 700px !important;
  top: 0px;

  label {
    color: #999999;
    font-size: 12px;
  }

  &__form {
    @include displayFlex;
    flex-direction: column;

    &__item {
      margin: 0px;

      div:nth-child(1) {
        padding-bottom: 6px;
      }
      &--required {
        .ant-form-item-label::after {
          content: "*";
          color: $nw-red;
          margin-left: 4px;
          display: inline-block;
        }
      }
    }
  }

  &__innerContainer {
    @include displayFlex;
    flex-direction: row;
    justify-content: space-between;
    gap: 17px;
    div {
      flex-grow: 1;
    }

    @media only screen and (max-width: 585px) {
      @include displayFlex;
      flex-direction: column;
    }
  }
}
.ant-modal-header {
  background-color: $nw-greyLighter;
}

.ant-modal {
  padding: 0px;
  // top: 0px;
}

.ant-modal-wrap {
  @include displayFlex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// .ant-modal-body {
//   padding: 18px 28px;
// }
