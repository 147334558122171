$global-font-family: "Helvetica Neue", helvetica, arial, sans-serif;

$radius-small: 3px;
$radius-mid: 8px;
$radius-big: 12px;

$card-height-normal: 250px;

$btn-height-normal: 36px;

$font-size-small: 14px;

$max-desktop-width: 1600px;