@import "styles/styles.scss";

.heroBanner {
    span + h1 {
        margin-top: 0 !important;
    }

    &__highlighterNote {
        font-size: $font-size-small;
        color: $nw-red;
    }

    &__content {
        @include displayFlex;
        flex-wrap: wrap-reverse;
        justify-content: center;
        padding: 10px 0;
        gap: 2%;

        &--text {
            padding-top: 18px;
            flex: 1;
        }

        &--image {
            @include desktop {
                width: 55%;
            }

            width: 100%;
            height: auto;
        }
    }
}