@import "styles/shared";

$header-height: 80px;

.sidebar {
  @include displayFlex;
  flex-direction: row;
  height: 100%;

  @include mobile {
    @include displayFlex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  nav {
    position: fixed;
    width: 22%;
    max-width: 0.22 * $max-desktop-width;
    @include displayFlex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid #f0f0f0;
    padding-left: 18px;

    .ant-anchor-ink::before {
      width: 0px;
    }

    @include mobile {
      position: fixed;
      top: $header-height;
      left: 0;
      width: 100%;
      justify-content: center;
      padding-left: 0px;
      background-color: #fafafa;
      border-bottom: 1px solid #e6e8e9;
      z-index: 2;
      max-width: 100%;
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      .ant-anchor {
        height: 100%;
        @include displayFlex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .ant-tabs-nav {
        margin-bottom: 0px;
        &::before {
          border: 0px !important;
        }
      }

      .ant-anchor-link {
        margin-left: 18px;
        padding-left: 0px;
        height: 45px;
        @include displayFlex;
        flex-direction: column;
        justify-content: center;
      }

      .ant-anchor-link-active {
        border-bottom: solid 2px #fd0826;
        .ant-anchor-link-title {
          color: #fd0826;
        }
      }
    }

    ul {
      border: 0px;
      background-color: transparent !important;
      overflow: hidden;
    }
    li {
      width: 100%;
      background-color: inherit !important;
      list-style-type: none;
      @include displayFlex;
      justify-content: flex-start;

      @include mobile {
        width: fit-content;
      }
    }

    @media print {
      display: none;
    }
  }

  main {
    margin-left: 22%;
    width: 78%;
    max-width: 0.78 * $max-desktop-width;
    padding-left: 35px;
    padding-right: 61px;

    @media print {
      margin: 0px;
      width: 100%;
    }
  }
  @include mobile {
    main {
      margin-left: 0%;
      width: 100%;
      padding: 48px 18px 0px 18px;
    }
  }
}
