$nw-red: #fd0826;
$nw-dark: #454545;
$nw-darker: #202020;
$nw-grey: #707070;
$nw-greyLight: #f6f9fc;
$nw-greyLighter: #f4f4f4;
$nw-greyDark: #9098a4;
$nw-white: #ffffff;

$color-body: $nw-greyLight !default;
$color-footer: $nw-darker !default;

$color-primary: $nw-red !default;

$color-text: $nw-dark !default;
$color-text-inverted: $nw-white !default;
$color-text-heading: $nw-darker !default;
$color-text-sub: $nw-grey !default;

$color-border: $nw-greyLighter !default;
