@import "styles/styles.scss";

.customization {
  line-height: 1.5;
  &__text {
    &--bold {
      font-weight: 700;
    }
    &--center {
      text-align: center;
    }
    &--underline {
      text-decoration: underline;
    }
  }
  .ant-table {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .ant-pagination {
    visibility: hidden;
    height: 0px;
  }
}
