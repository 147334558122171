body {
  font-family: $global-font-family;

  // temporary overwrite heading text size until
  // we find know how to do in antd less theming
  @include mobile {
    h1 { font-size: 30px !important; }
    h2 { font-size: 26px !important; }
    h3 { font-size: 22px !important; }
    h4 { font-size: 18px !important; }
    h5 { font-size: 16px !important; }
  }
}
