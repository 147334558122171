@import "styles/styles.scss";

.skeleton {
  padding: 30px 50px;
  
  @include desktop {
    max-width: $max-desktop-width;
    margin: 0 auto;
  }

  &__mobileMenu {
    display: block;

    @include desktop {
      display: none;
    }
  }

  &__menu {
    padding-right: 45px;
    display: none;

    @include desktop {
      display: flex;
    }
  }

  &__hero {
    justify-content: space-around;
    align-items: center;
  }
}