@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// All less variables could be found in https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// Base
@border-radius-base: 3px;

// Color
@primary-color: #fd0826;

// Font
@font-family: "Helvetica Neue", helvetica, arial, sans-serif;
@font-size-base: 16px;
@font-size-sm: 14px;
@line-height-base: 1.4715;

// Heading
@heading-1-size: 40px; // ceil(@font-size-base * 2.71);
@heading-2-size: 30px; //ceil(@font-size-base * 2.14);
@heading-3-size: 26px; // ceil(@font-size-base * 1.71);
@heading-4-size: 20px; // ceil(@font-size-base * 1.42);
@heading-5-size: 17px; // ceil(@font-size-base * 1.14);

// vertical paddings
// @padding-lg: 24px; // containers
// @padding-md: 16px; // small containers and buttons
// @padding-sm: 12px; // Form controls and items
// @padding-xs: 8px; // small items
// @padding-xss: 4px; // more small

// vertical padding for all form controls
// @control-padding-horizontal: @padding-sm;
// @control-padding-horizontal-sm: @padding-xs;

// vertical margins
// @margin-lg: 24px; // containers
// @margin-md: 16px; // small containers and buttons
// @margin-sm: 12px; // Form controls and items
// @margin-xs: 8px; // small items
// @margin-xss: 4px; // more small

// Card
@card-shadow: 0px 3px 8px #0000000A;
@card-radius: 12px;

// Button
@btn-height-base: 36px;
@btn-font-weight: bold;

@divider-color: rgba(0, 0, 0, 8%);