@import "styles/shared";

.productCard {
  height: auto;
  width: 100%;
  overflow: hidden !important;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 3px 8px #0000000A;

  &__mobileImage {
    @include desktop {
      display: none;
    }
  }

  &__desktopImage {
    display: block;

    @include mobile {
      display: none;
    }
  }

  .ant-card-grid {
    padding: 16px;
  }

  @include desktop {
    height: 100%;
  }

  &__content {
    @include desktop {
      display: inline-block;
    }

    height: 100%;
    display: none;
    vertical-align: top;
    overflow: hidden;

    &:nth-child(1) {
      height: 100%;
      width: 54%;
      padding: 0 10px;
      background-color: $nw-greyDark;

      img {
        width: 100%;
        height: 100%;
        object-position: bottom;
        object-fit: contain;
      }
    }

    &:nth-child(2) {
      width: 46%;
    }

    &__list {
      margin-top: 7px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;
      padding-left: 25px;

      li {
        list-style-type: circle;
      }
    }

    &__title {
      margin-bottom: 0 !important;
    }
    
    &__text {
      font-size: 14px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
    }

    &__details {
      height: 100%;
      @include displayFlex;
      flex-direction: column;
      justify-content: space-between;

      h5 {
        padding-bottom: 10px;
      }
    }
  }

  h4 {
    color: $nw-darker;
    margin: 0px 0px 6px 0px;
  }

  &--onMobile {
    @include desktop {
      display: none;
    }

    &__description__container {
      padding: 18px 20px 14px 14px;
      
      h5 {
        line-height: 120% !important;
      }
    }

    &__list__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 10px 0;

      ul {
        margin-bottom: 0;
      }
    }

    &__imageHolder__container {
      background: $nw-greyDark;
      border-radius: 12px 12px 0 0;

      img {
        width: 100%;
        object-position: 0 1.5rem;
        height: 100%;
        object-fit: cover;
      }

      &:empty {
        height: 32vh;
      }
    }
  }
}

