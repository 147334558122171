@import "styles/styles.scss";

$header-height: 80px;
$header-padding: 0px 50px;
$header-padding-mobile: 0px 24px;

.app-navbar {
  @include displayFlex;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  padding: $header-padding-mobile;
  @include desktop {
    padding: $header-padding;
    max-width: $max-desktop-width;
  }

  &--withShadow {
    @include box-shadow(0 2px 8px rgba(35, 35, 35, 0.125));
  }

  &__logo {
    @include displayFlex;
    align-items: center;

    img {
      margin-right: 16px;
    }

    h1,
    h2,
    h3,
    h4 {
      margin: unset;
      color: $color-primary;
    }

    &__title {
      @include displayFlex;
      flex-direction: column;
      align-items: center;
      h1,
      h2,
      h3 {
        line-height: 120%;
        text-transform: uppercase;
      }
      small {
        font-size: 10.5px;
        line-height: 100%;
        display: block;
        color: $color-text-sub;
      }
    }
  }

  &__toggleMenuBtn {
    display: block;

    @include desktop {
      display: none;
    }

    @media print {
      display: none;
    }
  }

  &__nav {
    display: none;
    &.toggled--onMobile {
      width: 100vw;
      height: calc(100vh - $header-height);
      display: flex;
      position: fixed;
      top: $header-height;
      left: 0;
    }
    @keyframes appearInOut {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    animation: appearInOut 0.2s;

    @include desktop {
      display: block;
      min-width: 480px;
      animation: none;
    }

    .ant-menu-horizontal {
      border-bottom: unset;
      max-height: 50px;
      li {
        max-height: 50px;
        @include displayFlex;
        align-items: center;
      }
    }
    .ant-menu-vertical {
      width: 100%;
    }

    @media print {
      display: none;
      margin: 0px;
    }
  }

  .spacer {
    flex-grow: 1;
  }
}
