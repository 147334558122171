@import "styles/styles.scss";

.integration {
  line-height: 1.5;
  a {
    color: #4180fb;
  }
  &__text {
    &--unbold {
      font-weight: 400;
    }
    &--bold {
      font-weight: 700;
    }
    &--code {
      font-family: "Courier New", Courier, monospace;
    }
    &--emphasis {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
