@import "styles/styles.scss";

.standardApps {
  padding: 20px !important;
  @include desktop {
    padding: 20px 0 40px 0 !important;
  }
  &__header {
    // margin: 50px 24px;
    margin-bottom: 0px;
    padding: 40px 20px 20px;
    text-align: center;

    h2 { 
      color: $color-primary;
      margin-bottom: 24px;
    }

    &__highlighterNote {
      font-size: $font-size-small;
      color: $color-primary;
    }

    &__name {
      text-align: center;
      margin-top: 0 !important;
    }
  }

  &__appContainer {
    &__features {
      margin-top: 16px;
      list-style-type: none;
      padding-left: 10px;

      &__feature {
        @include displayFlex;
        margin: 6px 0;

        &__checkIcon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
      .anticon {
        padding-right: 10px;
      }
      svg {
        fill: $nw-red;
      }
    }
  }

  .heroBanner {
    padding: 0;
  }
  .heroBanner span + h1 {
    font-size: 26px;
  }
}
