@import "../../styles/shared";

.microCard {
  display: inline-block;
  border: 1px solid #f0f0f0;
  border-radius: $radius-big;
  width: 100%;
  overflow: hidden;
  height: 100%;
  box-shadow: 0px 3px 8px #0000000A;

  &__description__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 12px 4px 12px;
    align-items: center;
  }

  &__text__container {
    display: flex;
    flex-direction: column;
    margin: 0px;

    h5 {
      line-height: 120% !important;
      margin-bottom: 6px;
    }
    p, .ant-typography-secondary {
      font-size: 14px !important;
      line-height: 100%;
      // margin-bottom: 18px;
    }

    &--desktop {
      display: none;
      
      @include desktop {
        display: block;
      }
    }
  }

  &__imageHolder__container {
    background: $nw-greyDark;
    border-radius: 12px 12px 0 0;

    img {
      width: 100%;
        object-position: bottom;
        object-fit: cover;
    }

    &:empty {
      height: 32vh;
    }
  }
}
