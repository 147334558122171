@import "styles/styles.scss";

.enterpriseCmpt {
    // border-left: 1px dashed $color-border;

    &__header {
        padding-top: 30px;
        text-align: center;
        margin-bottom: 30px;
        @include desktop {
            margin: 40px 24px 50px 24px;
        }
        h2 {
            color: $color-primary;
            margin-bottom: 24px;
        }
    }

    &__sectionHeader {
        // padding-left: 12px;
        // border-left: solid 1px $color-primary;
    }

    &__content {
        padding: 30px 0;
    }
}

