@import "styles/styles.scss";

.introduction {
    padding: 20px;
    // text-align: center;
    background: white;
    border-top: 1px solid $color-border;

    @include desktop {
        padding: 30px 40px;
    }

    section {
        @include desktop {
            max-width: 98%;
            padding: 0 30px;
        }
    }
}