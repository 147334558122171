@import "styles/styles.scss";

.appFooter {
    text-align: center;
    img {
        display: block;
        margin: 0 auto 20px auto;
    }
    span, small {
        color: white;
        display: block;
    }
    small {
        opacity: 0.75;
        margin-top: 10px;
    }
}